import { marked } from 'marked';
import {
  forwardRef,
  type TextareaHTMLAttributes,
  useImperativeHandle,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { useLiveCallback } from '../hooks/useLiveCallback';
import { useAwaitFullScreenConfirmCancelModal } from './ConfirmCancelModalContext';
import { ModalWrapper } from './ConfirmCancelModalContext/ModalWrapper';

type MarkdownEditorProps = {
  initValue: string;
  onSave: (val: string) => void;
  onCancel: () => void;
};

export function MarkdownEditor(props: Partial<MarkdownEditorProps>) {
  const { onSave, onCancel } = props;
  const [value, setValue] = useState(props.initValue ?? '');
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    (async function init() {
      if (!ref.current) return;
      ref.current.innerHTML = await marked(value);
    })();
  }, [value]);

  const onChange = async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(e.target.value);
    if (!ref.current) return;
    ref.current.innerHTML = await marked(e.target.value);
  };

  return (
    <div className='w-full h-full flex flex-col gap-4 py-4 px-8'>
      <header className='flex items-center justify-between'>
        <h1 className='text-center text-white font-bold text-2xl'>
          Markdown Editor
        </h1>
        <div className='flex justify-center items-center gap-4'>
          {onCancel && (
            <button
              type='button'
              className='btn-secondary w-32 h-10'
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
          {onSave && (
            <button
              type='button'
              className='btn-primary w-32 h-10 flex items-center justify-center'
              onClick={() => onSave(value)}
            >
              Save
            </button>
          )}
        </div>
      </header>
      <div className='flex w-full h-full gap-5'>
        <div className='w-1/2 h-full flex flex-col gap-2'>
          <header>Input</header>
          <textarea
            className='field mb-0 flex-1 resize-none px-3 py-2'
            value={value}
            onChange={onChange}
          />
        </div>
        <div className='w-1/2 h-full flex flex-col gap-2'>
          <header>Preview</header>
          <div
            ref={ref}
            className='border border-secondary flex-1 rounded-xl markdown-body px-3 py-2'
          ></div>
        </div>
      </div>
    </div>
  );
}

export function useOpenMarkdownEditor(): (
  props: Omit<MarkdownEditorProps, 'onCancel'>
) => void {
  const triggerFullScreenModal = useAwaitFullScreenConfirmCancelModal();

  return useLiveCallback((props: Omit<MarkdownEditorProps, 'onCancel'>) => {
    triggerFullScreenModal({
      kind: 'custom',
      element: (p) => {
        const handleSave = (value: string) => {
          props.onSave(value);
          p.internalOnConfirm();
        };

        return (
          <ModalWrapper borderStyle='gray' containerClassName='w-[90%] h-[90%]'>
            <MarkdownEditor
              {...props}
              onSave={handleSave}
              onCancel={p.internalOnCancel}
            />
          </ModalWrapper>
        );
      },
    });
  });
}

export function MarkdownBody(props: {
  body: string;
  styles?: React.CSSProperties;
}) {
  const { body, styles } = props;

  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    (async function init() {
      if (!ref.current) return;
      ref.current.innerHTML = await marked(body);
    })();
  }, [body]);

  return (
    <div className='markdown-body' ref={ref} style={styles}>
      {body}
    </div>
  );
}

type MarkdownTextAreaProps = TextareaHTMLAttributes<HTMLTextAreaElement> & {
  onMarkdownEditorSave?: (val: string) => void;
};

export const MarkdownTextArea = forwardRef<
  HTMLTextAreaElement,
  MarkdownTextAreaProps
>((props, forwardedRef) => {
  const { onMarkdownEditorSave, ...rest } = props;
  const ref = useRef<HTMLTextAreaElement | null>(null);
  useImperativeHandle(forwardedRef, () => ref.current as HTMLTextAreaElement);
  const openMarkdownEditor = useOpenMarkdownEditor();
  const handleSave = useLiveCallback((val: string) => {
    if (onMarkdownEditorSave) {
      onMarkdownEditorSave(val);
    }
    if (!ref.current) return;
    ref.current.value = val;
    const event = new Event('change', { bubbles: true });
    ref.current.dispatchEvent(event);
  });
  return (
    <div className='relative'>
      <textarea
        ref={ref}
        className='w-full h-full min-h-30 field mb-0 px-3 py-2 scrollbar'
        {...rest}
      />
      {
        <button
          type='button'
          className='absolute right-2 bottom-1 text-xl'
          onClick={() =>
            openMarkdownEditor({
              initValue: ref.current?.value ?? '',
              onSave: handleSave,
            })
          }
        >
          <span className='text-white'>🅼</span>
        </button>
      }
    </div>
  );
});
